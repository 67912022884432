.container-lg {
  /* background-color: #fafcfe; */
  color: black;
  opacity:0.7;
}

.company-story{
    text-align: center;
}

.introduction{
    text-align: center;
    align-items: center;
}